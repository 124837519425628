/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  margin: 0px;
  height: 100%;
  width: 100%;
}

html,
body,
#root,
#root > div {
  min-height: 100vh;
}

.min-height {
  min-height: 100vh;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer components {
  .main {
    @apply relative flex-1 z-0 overflow-y-auto focus:outline-none bg-secondary;
  }
  .button-primary {
    @apply relative inline-flex items-center px-4 py-2 shadow-sm text-sm font-medium rounded-md text-black bg-primary hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
  }
  .top-bar {
    @apply border-b border-gray-200 px-4 py-4 flex sm:items-center sm:justify-between sm:px-6 lg:px-8 bg-white;
  }
  .top-bar-title {
    @apply flex-1 min-w-0;
  }
  .top-bar-title-h1 {
    @apply text-3xl font-medium leading-6 text-black sm:truncate py-2;
  }
  .content {
    @apply max-w-7xl mx-auto px-4 pt-16 pb-48 sm:px-6 lg:px-16 overflow-x-auto;
  }
  .content-title {
    @apply text-lg leading-6 font-medium text-gray-900 pt-6 pb-2;
  }
  .table-container {
    @apply inline-block min-w-full mt-4 shadow border-b border-gray-200 sm:rounded-lg;
  }
  .table {
    @apply min-w-full divide-y divide-gray-200 bg-white rounded-lg;
  }
  .table-header-col {
    @apply px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider;
  }
  .table-col-bold {
    @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-black;
  }
  .table-col {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-500;
  }
  .table-col-action {
    @apply px-6 py-4 whitespace-nowrap text-right text-sm font-medium;
  }
  .card {
    @apply mt-5 border border-gray-200 px-4 py-5 rounded-lg bg-white;
  }
  .card-header-container {
    @apply bg-white px-4 py-5 border-b border-gray-200 sm:px-2;
  }
  .card-header-items {
    @apply -ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap;
  }
  .card-content-row {
    @apply py-4 pl-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4;
  }
  .card-content-row-title {
    @apply text-sm font-medium text-gray-500;
  }
  .card-content-row-data {
    @apply mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2;
  }
}
